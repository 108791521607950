import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { DatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'

import useFirestoreQuery from './useFirestoreQuery'

import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

function OppCard ({ oppId, opp, docRef, handleSave }) {
  const [localOpp, setLocalOpp] = useState(opp)

  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Typography variant='h6'>
          Opportunity Overview
        </Typography>
        <Box>
          <form className={classes.root} noValidate autoComplete='off'>
            <TextField label='Opportunity Name' value={localOpp.name} onChange={e => setLocalOpp({ ...localOpp, name: e.currentTarget.value })} />
            <br />
            <TextField label='Account' value={localOpp.account} onChange={e => setLocalOpp({ ...localOpp, account: e.currentTarget.value })} />
            <br />
            <TextField label='Stage' value={localOpp.stage} onChange={e => setLocalOpp({ ...localOpp, stage: e.currentTarget.value })} />
            <br />
            <DatePicker
              variant='inline'
              label='Close Date'
              value={localOpp?.closeDate?.toDate && localOpp.closeDate.toDate()}
              onChange={e => {
                setLocalOpp({ ...localOpp, closeDate: firebase.firestore.Timestamp.fromDate(e.toJSDate()) })
              }}
            />
            <br />
            <TextField label='Deal Size' value={localOpp.dealSize} onChange={e => setLocalOpp({ ...localOpp, dealSize: e.currentTarget.value })} />
            <br />
            <br />
            <Button
              variant='contained' color='primary'
              onClick={() => {
                docRef.set(localOpp, { merge: true }).then(handleSave)
              }}
            >Save
            </Button>
          </form>
        </Box>
      </CardContent>
    </Card>
  )
}

export default OppCard
