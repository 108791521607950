import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext } from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import {
  useParams
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

import TaskCard from './TaskCard'
import { AuthContext } from './Auth'

export default function TaskView () {
  const { taskId, oppId } = useParams()
  const user = useContext(AuthContext)
  const db = firebase.firestore()
  const docRef = db
    .collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('tasks')
    .doc(taskId)
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })

  if (isLoading) return 'Loading...' + oppId

  if (error) {
    return 'An error has occurred: ' + error.message
  }

  const task = data.data()

  return (
    <Container>
      <Typography variant='h6'>{task.name}</Typography>
      <TaskCard
        taskId={data.id}
        task={task}
        docRef={docRef}
        handleSave={() => { refetch() }}
      />
    </Container>
  )
}
