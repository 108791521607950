
import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'

function useFirestoreQuery ({ docRef }) {
  const [state, setState] = useState({ isLoading: true, data: null })
  const unsubscribeRef = useRef(null)
  const docRefRef = useRef(null)
  if (!docRefRef.current || !docRef.isEqual(docRefRef.current)) {
    docRefRef.current = docRef
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
      unsubscribeRef.current = null
    }
    unsubscribeRef.current = docRef.onSnapshot(snapshot => {
      setState({ isLoading: false, data: snapshot })
    }, error => {
      console.debug(error)
      setState({ isLoading: false, data: null, error: error })
    })
  }

  useEffect(() => {
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
        unsubscribeRef.current = null
      }
    }
  }, [])

  function refetch () {
    docRefRef.current = null
    setState({ ...state })
  }

  return { ...state, refetch }
}

export default useFirestoreQuery
