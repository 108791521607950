import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import Modal from '@material-ui/core/Modal'
import { XGrid } from '@material-ui/x-grid'
import BasicModal from './BasicModal'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const addObj = ({ tenantId, oppId, name, createdByUser, title, email, phone, status, type }) => {
  const db = firebase.firestore()
  return db.collection('tenants')
    .doc(tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('contacts')
    .add({
      name: name,
      status: status,
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdByUser: createdByUser,
      email: email,
      phone: phone,
      title: title,
      type: type
    }).then(docRef => {
      return docRef.id
    })
}

export default function NewContactModal ({ open, onClose, onCreate, oppId }) {
  const user = useContext(AuthContext)

  const [localObj, setLocalObj] = useState({
    name: '',
    title: '',
    email: '',
    phone: '',
    type: 'Prospect',
    status: 'Unknown'
  })

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title='Add Contact'
      height={580}
    >
      <>
        <TextField label='Name' value={localObj.name} onChange={e => setLocalObj({ ...localObj, name: e.currentTarget.value })} />
        <br /><br />
        <TextField label='Title' value={localObj.title} onChange={e => setLocalObj({ ...localObj, title: e.currentTarget.value })} />
        <br /><br />
        <TextField label='Email' value={localObj.email} onChange={e => setLocalObj({ ...localObj, email: e.currentTarget.value })} />
        <br /><br />
        <TextField label='Phone' value={localObj.phone} onChange={e => setLocalObj({ ...localObj, phone: e.currentTarget.value })} />
        <br /><br />
        <br /><br />
        <FormControl>
          <InputLabel id='new-contact-status-select'>Status</InputLabel>
          <Select
            labelId='new-contact-status-select'
            id='new-contact-status-select'
            value={localObj.status}
            onChange={e => setLocalObj({ ...localObj, status: e.target.value })}
            style={{ width: 150 }}
          >
            <MenuItem value='Unknown'>Unknown</MenuItem>
            <MenuItem value='Neutral'>Neutral</MenuItem>
            <MenuItem value='Promoter'>Promoter</MenuItem>
            <MenuItem value='Detractor'>Detractor</MenuItem>
          </Select>
        </FormControl>
        <br /><br />
        <FormControl>
          <InputLabel id='new-contact-type-select'>Type</InputLabel>
          <Select
            labelId='new-contact-type-select'
            id='new-contact-type-select'
            value={localObj.type}
            onChange={e => setLocalObj({ ...localObj, type: e.target.value })}
            style={{ width: 150 }}
          >
            <MenuItem value='Employee'>Employee</MenuItem>
            <MenuItem value='Prospect'>Prospect</MenuItem>
            <MenuItem value='Customer'>Customer</MenuItem>
            <MenuItem value='Partner'>Partner</MenuItem>
          </Select>
        </FormControl>
        <br /><br />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            addObj({
              tenantId: user.data.tenantId,
              oppId,
              name: localObj.name,
              createdByUser: {
                id: user.uid,
                photoURL: user.data.photoURL || user.authUser.photoURL || null,
                displayName: user.authUser.displayName
              },
              title: localObj.title,
              status: localObj.status,
              email: localObj.email,
              phone: localObj.phone,
              type: localObj.type
            }).then(() => {
              setLocalObj({
                name: '',
                title: '',
                email: '',
                phone: '',
                type: 'Prospect',
                status: 'Unknown'
              })
              onCreate && onCreate()
              onClose()
            })
          }}
        >Add New Contact
        </Button>
      </>
    </BasicModal>
  )
}
