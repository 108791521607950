import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const firebaseConfig = {
  projectId: 'red-seeker-298202',
  appId: '1:462404147667:web:bad1e608832a3d4fce7726',
  storageBucket: 'red-seeker-298202.appspot.com',
  locationId: 'us-central',
  apiKey: 'AIzaSyDJmNDfdX7vGvbVkUduYQ_f-gRy4VD9zPk',
  authDomain: 'red-seeker-298202.firebaseapp.com',
  messagingSenderId: '462404147667',
  measurementId: 'G-DEWTWDSR56'
}
firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
