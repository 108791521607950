import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import ContactsIcon from '@material-ui/icons/Contacts'
import ListIcon from '@material-ui/icons/List'
import GridOnIcon from '@material-ui/icons/GridOn'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import SettingsIcon from '@material-ui/icons/Settings'
import SettingsPage from './SettingsPage'

import OppView from './OppView'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import OppList from './OppList'
import { Button } from '@material-ui/core'
import UserManagementPage from './UserManagement'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))

export default function MainApp () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Typography variant='h6' noWrap>
            OppTask
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem button component={RouterLink} to='/things'>
              <ListItemIcon> <GridOnIcon /></ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItem>
            <ListItem button component={RouterLink} to='/opps'>
              <ListItemIcon> <BusinessCenterIcon /></ListItemIcon>
              <ListItemText primary='Opportunities' />
            </ListItem>
            <ListItem button>
              <ListItemIcon> <ListIcon /></ListItemIcon>
              <ListItemText primary='Tasks' />
            </ListItem>
            <ListItem button>
              <ListItemIcon> <ContactsIcon /></ListItemIcon>
              <ListItemText primary='Contacts' />
            </ListItem>
            <ListItem button component={RouterLink} to='/settings'>
              <ListItemIcon> <SettingsIcon /></ListItemIcon>
              <ListItemText primary='Settings' />
            </ListItem>
            <ListItem button onClick={() => { firebase.auth().signOut() }}>
              <ListItemIcon> <LogoutIcon /></ListItemIcon>
              <ListItemText primary='Sign out' />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Switch>
          <Route path='/opps/:oppId'>
            <OppView />
          </Route>
          <Route path='/opps'>
            <OppList />
          </Route>
          <Route path='/bar'>
            <p>Bar here</p>
          </Route>
          <Route path='/settings/user_management'>
            <UserManagementPage />
          </Route>
          <Route path='/settings'>
            <SettingsPage />
          </Route>

        </Switch>
      </main>
    </div>
  )
}
