
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    margin: theme.spacing(2)
  }
}))

export default function BasicModal ({ title, open, onClose, width, height, children }) {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Paper style={{
        position: 'absolute',
        width: width || 500,
        height: height || 350,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden'
      }}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant='h5'>{title}</Typography>
        </Toolbar>
        <div className={classes.content}>
          {children}
        </div>
      </Paper>
    </Modal>
  )
}
