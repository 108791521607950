import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { XGrid } from '@material-ui/x-grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import NewTaskModal from './NewTaskModal'

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

export default function TaskList ({ oppId }) {
  const [modalOpen, setModalOpen] = useState(false)
  const db = firebase.firestore()
  const user = useContext(AuthContext)
  const docRef = db.collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('tasks')
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })

  const history = useHistory()

  return (
    <Container>
      <Typography variant='h5'>Tasks</Typography>
      <Paper>
        <div style={{ height: 500, width: '100%' }}>
          <XGrid
            error={error}
            pagination
            autoPageSize
            onRowClick={(params) => {
              history.push(`/opps/${oppId}/tasks/${params.row.id}`)
            }}
            rows={data?.docs.map((task, i) => {
              const taskData = task.data()
              return {
                id: task.id,
                name: taskData.name,
                status: taskData.status,
                createdByUser: taskData.createdByUser?.displayName,
                createdTimestamp: taskData.createdTimestamp ? DateTime.fromJSDate(taskData.createdTimestamp.toDate()).toLocaleString() : '',
                dueTimestamp: taskData.dueTimestamp ? DateTime.fromJSDate(taskData.dueTimestamp.toDate()).toLocaleString() : ''
              }
            }) || []}
            columns={[
              { field: 'name', headerName: 'Task', width: 200 },
              { field: 'status', headerName: 'Status', width: 120 },
              { field: 'createdByUser', headerName: 'Created By', width: 160 },
              { field: 'createdTimestamp', headerName: 'Created Date', width: 160 },
              { field: 'dueTimestamp', headerName: 'Due Date', width: 160 }
            ]}
            loading={isLoading}
            rowHeight={50}
          />
        </div>
      </Paper>
      <NewTaskModal oppId={oppId} open={modalOpen} onClose={() => setModalOpen(false)} />
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setModalOpen(true)
        }}
      >Add New Task
      </Button>
    </Container>
  )
}
