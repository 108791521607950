import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import Modal from '@material-ui/core/Modal'
import { XGrid } from '@material-ui/x-grid'
import BasicModal from './BasicModal'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const addTask = ({ tenantId, oppId, name, createdByUser, description }) => {
  const db = firebase.firestore()
  return db.collection('tenants')
    .doc(tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('tasks')
    .add({
      name: name,
      description: description,
      status: 'Not Started',
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdByUser: createdByUser,
      assignedToUser: createdByUser,
      visibility: 'team'
    }).then(docRef => {
      return docRef.id
    })
}

export default function NewTaskModal ({ open, onClose, onCreate, oppId }) {
  const user = useContext(AuthContext)

  const [localObj, setLocalObj] = useState({
    name: '',
    status: 'Not Started',
    description: ''
  })

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title='Add Task'
      height={480}
    >
      <>
        <TextField label='Name' value={localObj.name} onChange={e => setLocalObj({ ...localObj, name: e.currentTarget.value })} />
        <br /><br />
        <FormControl>
          <InputLabel id='new-task-status-select'>Status</InputLabel>
          <Select
            labelId='new-task-status-select'
            id='new-task-status-selectt'
            value={localObj.status}
            onChange={e => setLocalObj({ ...localObj, status: e.target.value })}
            style={{ width: 150 }}
          >
            <MenuItem value='Not Started'>Not Started</MenuItem>
            <MenuItem value='In Progress'>In Progress</MenuItem>
            <MenuItem value='Completed'>Completed</MenuItem>
          </Select>
        </FormControl>
        <br /><br />
        <TextField
          label='Description'
          value={localObj.description}
          multiline
          rows={6}
          style={{ width: 400 }}
          variant='outlined'
          placeholder='Description'
          onChange={e => setLocalObj({ ...localObj, description: e.currentTarget.value })}
        />
        <br />
        <br /><br />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            addTask({
              tenantId: user.data.tenantId,
              oppId,
              name: localObj.name,
              createdByUser: {
                id: user.uid,
                photoURL: user.data.photoURL || user.authUser.photoURL || null,
                displayName: user.authUser.displayName
              },
              description: localObj.description,
              status: localObj.status
            }).then(() => {
              setLocalObj({
                name: '',
                status: 'Not Started',
                description: ''
              })
              onCreate && onCreate()
              onClose()
            })
          }}
        >Add New Task
        </Button>
      </>
    </BasicModal>
  )
}
