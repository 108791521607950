import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { XGrid } from '@material-ui/x-grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import NewContactModal from './NewContactModal'

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

const addContact = ({ tenantId, oppId, name, createdByUser, email, phone, status, type, title }) => {
  const db = firebase.firestore()
  return db.collection('tenants')
    .doc(tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('contacts')
    .add({
      name: name,
      email: email,
      phone: phone,
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdByUser: createdByUser,
      status,
      type,
      title
    }).then(docRef => {
      return docRef.id
    })
}

export default function ContactList ({ oppId }) {
  const [modalOpen, setModalOpen] = useState(false)
  const db = firebase.firestore()
  const user = useContext(AuthContext)
  const docRef = db.collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('contacts')
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })

  const history = useHistory()

  return (
    <Container>
      <Typography variant='h5'>People</Typography>
      <Paper>
        <div style={{ height: 500, width: '100%' }}>
          <XGrid
            error={error}
            pagination
            autoPageSize
            onRowClick={(params) => {
              history.push(`/opps/${oppId}/people/${params.row.id}`)
            }}
            rows={data?.docs.map((contact, i) => {
              const contactData = contact.data()
              return {
                id: contact.id,
                name: contactData.name,
                title: contactData.title,
                email: contactData.email,
                phone: contactData.phone,
                status: contactData.status,
                type: contactData.type
              }
            }) || []}
            columns={[
              { field: 'name', headerName: 'Name', width: 200 },
              { field: 'title', headerName: 'Title', width: 120 },
              { field: 'email', headerName: 'Email', width: 160 },
              { field: 'phone', headerName: 'Phone', width: 160 },
              { field: 'status', headerName: 'Status', width: 160 },
              { field: 'type', headerName: 'Type', width: 160 }
            ]}
            loading={isLoading}
            rowHeight={50}
          />
        </div>
      </Paper>
      <NewContactModal
        oppId={oppId}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        onCreate={() => { refetch() }}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={() => setModalOpen(true)}
      >Add New Contact
      </Button>
    </Container>
  )
}
