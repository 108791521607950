import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import MainApp from './MainApp'
import React, { useContext, useEffect, useState, useRef } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { AuthContext, AuthProvider } from './Auth'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import { LicenseInfo } from '@material-ui/x-grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { nanoid } from 'nanoid'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'

LicenseInfo.setLicenseKey(
  'a78a6c8fda1f45a6bb6e73c7e730c87bT1JERVI6MjEwNDMsRVhQSVJZPTE2NDQyMTA2MjgwMDAsS0VZVkVSU0lPTj0x'
)

const queryClient = new QueryClient()

window.firebase = firebase

function LoginForm () {
  const auth = firebase.auth
  const authInstance = auth()
  // authInstance.tenantId = 'zzz'
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      auth.GoogleAuthProvider.PROVIDER_ID,
      auth.FacebookAuthProvider.PROVIDER_ID,
      auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  }
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authInstance} />
  )
}

function AuthCheck ({ children, alternative }) {
  const user = useContext(AuthContext)
  if (user.data) {
    return <>{children}</>
  } else {
    return <>{alternative}</>
  }
}

function SignupFlow () {
  const user = useContext(AuthContext)
  const [state, setState] = useState({
    name: '',
    acceptedTerms: false

  })

  function handleRegister () {
    const tenantId = nanoid()
    const db = firebase.firestore()
    const batch = db.batch()
    const newTenantRef = db.collection('tenants').doc(tenantId)
    batch.set(newTenantRef, {
      name: state.name,
      acceptedTerms: state.acceptedTerms,
      acceptedTermsTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdByUser: {
        id: user.uid,
        email: user.authUser.email
      }
    })
    const firstTenantUserDocRef = db.collection('tenants').doc(tenantId).collection('tenantUsers').doc(user.uid)
    batch.set(firstTenantUserDocRef, {
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      email: user.authUser.email,
      name: user.authUser.displayName,
      role: 'admin'
    })
    const myUserRef = db.collection('users').doc(user.uid)
    batch.set(myUserRef, { tenantId: tenantId }, { merge: true })

    return batch.commit().then(() => user.refetch())
  }

  return (
    <>
      <Container style={{ marginTop: 20 }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant='h3'>OppTask</Typography>
        </Paper>
      </Container>
      <Container style={{ marginTop: 20 }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant='h5'>Signup for a 14 day free trial!</Typography>
          <br />
          <>
            <TextField
              label='Company Name'
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
            <br /><br />

            <FormControlLabel
              control={<Checkbox
                value={state.acceptedTerms}
                onChange={(e) => setState({ ...state, acceptedTerms: e.target.checked })}
                       />}
              label='I accept the terms and conditions'
            />
            <br /><br />
            <Button
              variant='contained'
              color='primary'
              disabled={!state.acceptedTerms || state.name.length < 1}
              onClick={handleRegister}
            >Create Account
            </Button>
          </>
        </Paper>
      </Container>
    </>
  )
}

function LoginOrSignup () {
  const { data } = useContext(AuthContext)
  if (typeof data === 'undefined') {
    return (<SignupFlow />)
  } else {
    return (<LoginForm />)
  }
}

function App () {
  return (
    <>
      <CssBaseline />

      <Router>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AuthCheck
                alternative={<LoginOrSignup />}
              >
                <MainApp />
              </AuthCheck>
            </AuthProvider>
          </QueryClientProvider>
        </MuiPickersUtilsProvider>
      </Router>
    </>

  )
}

export default App
