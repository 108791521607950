import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import Modal from '@material-ui/core/Modal'
import { XGrid } from '@material-ui/x-grid'
import BasicModal from './BasicModal'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const addUser = ({ tenantId, name, email, role }) => {
  const db = firebase.firestore()
  return db.collection('tenants').doc(tenantId).collection('tenantUsers').add({
    name: name,
    createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    role: role,
    email: email
  }).then(docRef => {
    return docRef.id
  })
}

export default function NewUserModal ({ open, onClose, onCreate }) {
  const user = useContext(AuthContext)

  const [localUser, setLocalUser] = useState({
    name: '',
    email: '',
    role: ''
  })

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title='Add User'
    >
      <>
        <TextField label='Name' value={localUser.name} onChange={e => setLocalUser({ ...localUser, name: e.currentTarget.value })} />
        <br />
        <TextField label='Email' value={localUser.email} onChange={e => setLocalUser({ ...localUser, email: e.currentTarget.value })} />
        <br />
        <FormControl>
          <InputLabel id='user-role-select'>Role</InputLabel>
          <Select
            labelId='user-role-select'
            id='user-role-select'
            value={localUser.role}
            onChange={e => setLocalUser({ ...localUser, role: e.target.value })}
            style={{ width: 150 }}
          >
            <MenuItem value='admin'>Admin</MenuItem>
            <MenuItem value='team'>Team Member</MenuItem>
          </Select>
        </FormControl>
        <br /><br />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            addUser({
              tenantId: user.data.tenantId,
              name: localUser.name,
              email: localUser.email,
              role: localUser.role
            }).then((e) => {
              setLocalUser({
                name: '',
                email: '',
                role: ''
              })
              onCreate && onCreate()
              onClose()
            })
          }}
        >Create
        </Button>
      </>
    </BasicModal>
  )
}
