
import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

const AuthContext = React.createContext()

function getAppUser (userId) {
  const db = firebase.firestore()
  return db.collection('users').doc(userId).get()
}

function AuthProvider ({ children }) {
  const [authUser, setAuthUser] = useState(null)
  const [appUser, setAppUser] = useState(null)

  function refetchAppUser () {
    if (!authUser.uid) {
      return
    }
    return getAppUser(authUser.uid).then(result => {
      const resultData = result.data()
      setAppUser(resultData)
    })
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthUser(user)
        getAppUser(user.uid).then(result => {
          const resultData = result.data()
          setAppUser(resultData)
        })
      } else {
        setAuthUser(null)
      }
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{ uid: authUser?.uid, authUser, data: appUser, refetch: refetchAppUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
