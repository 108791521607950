import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { XGrid } from '@material-ui/x-grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import NewContactModal from './NewContactModal'
import { nanoid } from 'nanoid'
import { DropzoneAreaBase } from 'material-ui-dropzone'

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

function dateFormat (d) {
  if (!d) {
    return ''
  }
  const l = DateTime.fromJSDate(d.toDate())
  return l.toLocaleString()
}

const addFileDoc = ({ tenantId, oppId, fileId, fileName, createdByUser, visibility }) => {
  const db = firebase.firestore()
  return db.collection('tenants')
    .doc(tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('files')
    .doc(fileId)
    .set({
      id: fileId,
      fileName: fileName,
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      createdByUser: createdByUser,
      visibility: visibility
    })
}

const getFileRef = ({ tenantId, oppId, fileId, version, fileName }) => {
  const ref = firebase.storage()
    .ref()
    .child('tenants')
    .child(tenantId)
    .child('opps')
    .child(oppId)
    .child('files')
    .child(fileId)
    .child('versions')
    .child(version + '')
    .child(fileName)
  return ref
}

const uploadOppFile = ({ tenantId, oppId, fileName, file, createdByUser, visibility }) => {
  const fileId = nanoid()
  const ref = getFileRef({ tenantId, oppId, fileId, version: 1, fileName })
  const uploadTask = ref.put(file)
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    snapshot => {
      console.log('uploadTask snapshot', snapshot)
    },
    error => {
      console.log('uploadTask error', error)
    },
    () => {
      console.log('finished!')
    }
  )
  const docTask = uploadTask.then(snapshot => {
    addFileDoc({ tenantId, oppId, fileId, version: 1, fileName, createdByUser, visibility })
  })
  return docTask
}

export default function FileList ({ oppId }) {
  const db = firebase.firestore()
  const user = useContext(AuthContext)
  const docRef = db.collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('files')
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })
  const dragCountRef = useRef(0)

  const [isDragging, setIsDragging] = useState(false)

  console.log('isDragging', isDragging)

  const history = useHistory()

  useEffect(() => {
    const listener = (e) => {
      if (e.type === 'dragenter') {
        dragCountRef.current += 1
      } else if (e.type === 'dragleave') {
        dragCountRef.current -= 1
      } else if (e.type === 'drop') {
        dragCountRef.current = 0
      } else {
        console.log(e)
      }
      if (dragCountRef.current > 0 && !isDragging) {
        setIsDragging(true)
      } else if (dragCountRef.current < 1 && isDragging) {
        setIsDragging(false)
      }
    }
    window.document.addEventListener('dragenter', listener)
    window.document.addEventListener('dragleave', listener)
    window.document.addEventListener('drop', listener)
    return () => {
      window.document.removeEventListener('dragenter', listener)
      window.document.removeEventListener('dragleave', listener)
      window.document.removeEventListener('drop', listener)
    }
  })

  let dropHeight = 0
  if (isDragging) {
    dropHeight = 300
  }

  return (
    <Container>
      <Typography variant='h5'>Files</Typography>
      {isDragging && (
        <DropzoneAreaBase
          style={{ width: '100%', height: dropHeight }}
          filesLimit={1}
          maxFileSize={100000000}
          onAdd={loadedFiles => {
            uploadOppFile({
              fileName: loadedFiles[0].file.name,
              file: loadedFiles[0].file,
              oppId,
              tenantId: user.data.tenantId,
              visibility: 'team',
              createdByUser: {
                displayName: user.authUser.displayName,
                id: user.uid,
                photoURL: user.data.photoURL || user.authUser.photoURL || null
              }

            }).then(() => {
              refetch()
            })
          }}
        />
      )}

      <Paper>
        <div style={{ height: 500, width: '100%' }}>
          <XGrid
            error={error}
            pagination
            autoPageSize
            onRowClick={(params) => {
              history.push(`/opps/${oppId}/files/${params.row.id}`)
            }}
            rows={data?.docs.map((row, i) => {
              const rowData = row.data()
              return {
                id: row.id,
                fileName: rowData.fileName,
                createdByName: rowData.createdByUser.displayName,
                createdTimestamp: dateFormat(rowData.createdTimestamp),
                visibility: rowData.visibility
              }
            }) || []}
            columns={[
              { field: 'fileName', headerName: 'Name', width: 300 },
              { field: 'createdByName', headerName: 'Uploaded By', width: 200 },
              { field: 'createdTimestamp', headerName: 'Upload Date', width: 160 },
              { field: 'visibility', headerName: 'Share Settings', width: 160 }
            ]}
            loading={isLoading}
            rowHeight={50}
          />
        </div>
      </Paper>
    </Container>
  )
}
