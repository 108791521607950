import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '@material-ui/lab/TabPanel'

import {
  useHistory,
  useRouteMatch,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
  , useParams
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

import OppCard from './OppCard'
import { AuthContext } from './Auth'
import TaskList from './TaskList'
import TaskView from './TaskView'
import ContactList from './ContactList'
import PersonView from './PersonView'
import FileList from './FileList'

export default function OppView () {
  const { oppId } = useParams()
  const user = useContext(AuthContext)
  const db = firebase.firestore()
  const docRef = db.collection('tenants').doc(user.data.tenantId).collection('opps').doc(oppId)
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })
  const route = useRouteMatch(`/opps/${oppId}/:suffix`)
  const history = useHistory()

  if (isLoading) return 'Loading...' + oppId

  if (error) {
    return 'An error has occurred: ' + error.message
  }

  const opp = data.data()

  const tabMap = {
    0: '/',
    1: '/tasks',
    2: '/people',
    3: '/org',
    4: '/files',
    5: '/notes'
  }

  let tab
  const suffix = route?.params?.suffix
  if (!route) {
    tab = 0
  } else if (suffix === 'tasks') {
    tab = 1
  } else if (suffix === 'people') {
    tab = 2
  } else if (suffix === 'org') {
    tab = 3
  } else if (suffix === 'files') {
    tab = 4
  } else if (suffix === 'notes') {
    tab = 5
  }

  return (
    <Container>
      <Typography variant='h4'>{opp.name}</Typography>
      <Tabs
        value={tab}
        indicatorColor='primary'
        textColor='primary'
        onChange={(event, newValue) => history.push(`/opps/${oppId}${tabMap[newValue]}`)}
      >
        <Tab label='Overview' />
        <Tab label='Tasks' />
        <Tab label='People' />
        <Tab label='Org Chart' />
        <Tab label='Files' />
        <Tab label='Notes' />
      </Tabs>
      <Switch>
        <Route path='/opps/:oppId/tasks/:taskId'>
          <TaskView oppId={oppId} />
        </Route>
        <Route path='/opps/:oppId/tasks'>
          <TaskList oppId={oppId} />
        </Route>
        <Route path='/opps/:oppId/people/:personId'>
          <PersonView oppId={oppId} />
        </Route>
        <Route path='/opps/:oppId/people'>
          <ContactList oppId={oppId} />
        </Route>
        <Route path='/opps/:oppId/org'>
          <Typography variant='h6'>Org Chart!</Typography>
        </Route>
        <Route path='/opps/:oppId/files'>
          <FileList oppId={oppId} />
        </Route>
        <Route path='/opps/:oppId/notes'>
          <Typography variant='h6'>Notes!</Typography>
        </Route>
        <Route path='/opps/:oppId/'>
          <OppCard
            oppId={data.id}
            opp={opp}
            docRef={docRef}
            handleSave={() => { refetch() }}
          />
        </Route>
      </Switch>
    </Container>
  )
}
