import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import { XGrid } from '@material-ui/x-grid'
import TextField from '@material-ui/core/TextField'
import NewUserModal from './NewUserModal'

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

function dateFormat (d) {
  if (!d) {
    return ''
  }
  const l = DateTime.fromJSDate(d.toDate())
  return l.toLocaleString()
}

export default function UserManagementPage () {
  const db = firebase.firestore()
  const user = useContext(AuthContext)
  const [modalOpen, setModalOpen] = useState()
  const docRef = db.collection('tenants')
    .doc(user.data.tenantId)
    .collection('tenantUsers')
    .limit(100)
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef, live: true
  })

  const history = useHistory()

  let rows = []
  if (data?.docs) {
    rows = data.docs.map((tenantUser, i) => {
      const tenantUserData = tenantUser.data()
      return {
        id: tenantUser.id,
        name: tenantUserData.name,
        email: tenantUserData.email,
        role: tenantUserData.role,
        lastSeen: tenantUserData.lastSeenTimestamp,
        reportsToUserName: tenantUserData.reportsToUser?.name
      }
    })
  }

  return (
    <Container>
      <Typography variant='h4'>Users</Typography>
      <Paper>
        <div style={{ height: 500, width: '100%' }}>
          <XGrid
            error={error}
            pagination
            loading={isLoading}
            rowHeight={56}
            autoPageSize
            onRowClick={(params) => {
              history.push(`/settings/user_management/${params.row.id}`)
            }}
            rows={rows}
            columns={[
              { field: 'name', headerName: 'Name', width: 200 },
              { field: 'email', headerName: 'Email Address', width: 250 },
              { field: 'lastSeenTimestamp', headerName: 'Last Seen', width: 120 },
              { field: 'reportsToUserName', headerName: 'Reports To', width: 160 },
              { field: 'role', headerName: 'Role', width: 160 }
            ]}
          />
        </div>
      </Paper>
      <NewUserModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCreate={(e) => console.log('created!', e)}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setModalOpen(true)
        }}
      >Add User
      </Button>
    </Container>
  )
}
