import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import Modal from '@material-ui/core/Modal'
import { XGrid } from '@material-ui/x-grid'
import BasicModal from './BasicModal'
import TextField from '@material-ui/core/TextField'

const addOpp = ({ tenantId, name, roles }) => {
  const db = firebase.firestore()
  return db.collection('tenants').doc(tenantId).collection('opps').add({
    name: name,
    createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    roles
  }).then(docRef => {
    return docRef.id
  })
}

export default function NewOppModal ({ open, onClose, onCreate }) {
  const user = useContext(AuthContext)

  const [localOpp, setLocalOpp] = useState({
    name: '',
    account: '',
    stage: ''
  })

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title='Create New Opportunity'
    >
      <>
        <TextField label='Opportunity Name' value={localOpp.name} onChange={e => setLocalOpp({ ...localOpp, name: e.currentTarget.value })} />
        <br />
        <TextField label='Account' value={localOpp.account} onChange={e => setLocalOpp({ ...localOpp, account: e.currentTarget.value })} />
        <br />
        <br />
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            addOpp({
              tenantId: user.data.tenantId,
              name: localOpp.name,
              account: localOpp.account,
              roles: {
                [user.uid]: 'team'
              }
            }).then((e) => {
              setLocalOpp({
                name: '',
                account: '',
                stage: ''
              })
              onCreate && onCreate()
              onClose()
            })
          }}
        >Create
        </Button>
      </>
    </BasicModal>
  )
}
