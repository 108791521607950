import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { DateTime } from 'luxon'
import { DatePicker } from '@material-ui/pickers'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import useFirestoreQuery from './useFirestoreQuery'

import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

function PersonCard ({ personId, person, docRef, handleSave }) {
  const [localPerson, setLocalPerson] = useState(person)

  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Typography variant='h6'>
          Person
        </Typography>
        <Box>
          <form className={classes.root} noValidate autoComplete='off'>
            <TextField label='Person Name' value={localPerson.name} onChange={e => setLocalPerson({ ...localPerson, name: e.currentTarget.value })} />
            <br />
            <br />
            <FormControl className={classes.formControl}>
              <InputLabel id='person-type'>Type</InputLabel>
              <Select
                labelId='person-type'
                id='person-type'
                value={localPerson.type}
                onChange={e => setLocalPerson({ ...localPerson, type: e.target.value })}
              >
                <MenuItem value='Employee'>Employee</MenuItem>
                <MenuItem value='Prospect'>Prospect</MenuItem>
                <MenuItem value='Customer'>Customer</MenuItem>
                <MenuItem value='Partner'>Partner</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl className={classes.formControl}>
              <InputLabel id='person-status'>Status</InputLabel>
              <Select
                labelId='person-status'
                id='person-status'
                value={localPerson.status}
                onChange={e => setLocalPerson({ ...localPerson, status: e.target.value })}
              >
                <MenuItem value='Unknown'>Unknown</MenuItem>
                <MenuItem value='Neutral'>Neutral</MenuItem>
                <MenuItem value='Promoter'>Promoter</MenuItem>
                <MenuItem value='Detractor'>Detractor</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <TextField
              label='Title'
              value={localPerson.title}
              onChange={e => setLocalPerson({ ...localPerson, title: e.currentTarget.value })}
            />
            <br />
            <br />
            <TextField
              label='Email'
              value={localPerson.email}
              onChange={e => setLocalPerson({ ...localPerson, email: e.currentTarget.value })}
            />
            <br />
            <br />
            <TextField
              label='Phone'
              value={localPerson.phone}
              onChange={e => setLocalPerson({ ...localPerson, phone: e.currentTarget.value })}
            />
            <br />
            <br />
            <Button
              variant='contained' color='primary'
              onClick={() => {
                docRef.set(localPerson, { merge: true }).then(handleSave)
              }}
            >Save
            </Button>
          </form>
        </Box>
      </CardContent>
    </Card>
  )
}

export default PersonCard
