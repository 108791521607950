import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext } from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import {
  useParams
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

import PersonCard from './PersonCard'
import { AuthContext } from './Auth'

export default function PersonView () {
  const { personId, oppId } = useParams()
  const user = useContext(AuthContext)
  const db = firebase.firestore()
  const docRef = db
    .collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .doc(oppId)
    .collection('contacts')
    .doc(personId)
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef
  })

  if (isLoading) return 'Loading...' + oppId

  if (error) {
    return 'An error has occurred: ' + error.message
  }

  const person = data.data()

  return (
    <Container>
      <Typography variant='h6'>{person.name}</Typography>
      <PersonCard
        personId={data.id}
        person={person}
        docRef={docRef}
        handleSave={() => { refetch() }}
      />
    </Container>
  )
}
