import './App.css'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import React, { useContext, useEffect, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from './Auth'
import { DateTime } from 'luxon'
import { XGrid } from '@material-ui/x-grid'
import TextField from '@material-ui/core/TextField'
import NewOppModal from './NewOppModal'

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

import useFirestoreQuery from './useFirestoreQuery'

function dateFormat (d) {
  if (!d) {
    return ''
  }
  const l = DateTime.fromJSDate(d.toDate())
  return l.toLocaleString()
}

export default function OppList () {
  const db = firebase.firestore()
  const user = useContext(AuthContext)
  const [modalOpen, setModalOpen] = useState()
  const docRef = db.collection('tenants')
    .doc(user.data.tenantId)
    .collection('opps')
    .where(`roles.${user.uid}`, '==', 'team') // We should have a way to have admin users ask for all the opps in the tenant
    .limit(100)
  const { isLoading, error, data, refetch } = useFirestoreQuery({
    docRef, live: true
  })

  const history = useHistory()

  let rows = []
  if (data?.docs) {
    rows = data.docs.map((opp, i) => {
      const oppData = opp.data()
      return {
        id: opp.id,
        opp: oppData.name,
        account: oppData.account,
        stage: oppData.stage,
        closeDate: dateFormat(oppData.closeDate),
        dealSize: oppData.dealSize
      }
    })
  }

  return (
    <Container>
      <Typography variant='h4'>Opportunities</Typography>
      <Paper>
        <div style={{ height: 500, width: '100%' }}>
          <XGrid
            error={error}
            pagination
            loading={isLoading}
            rowHeight={56}
            autoPageSize
            onRowClick={(params) => {
              console.log(params)
              history.push(`/opps/${params.row.id}`)
            }}
            rows={rows}
            columns={[
              { field: 'opp', headerName: 'Opportunity', width: 200 },
              { field: 'account', headerName: 'Account', width: 150 },
              { field: 'stage', headerName: 'Stage', width: 120 },
              { field: 'closeDate', headerName: 'Close Date', width: 160 },
              { field: 'dealSize', headerName: 'Deal Size', width: 120 }

            ]}
          />
        </div>
      </Paper>
      <NewOppModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCreate={(e) => console.log('created!', e)}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setModalOpen(true)
        }}
      >Add New Opportunity
      </Button>
    </Container>
  )
}
