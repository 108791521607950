import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import PeopleIcon from '@material-ui/icons/People'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import PaymentIcon from '@material-ui/icons/Payment'
import SvgIcon from '@material-ui/icons/People'
import { ReactComponent as ContentCopyIcon } from './material-icons-copy/content_copy-24px.svg'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

export default function SettingsPage () {
  const classes = useStyles()

  return (
    <Container maxWidth='md' style={{ marginTop: 100 }}>
      <Grid
        container
        spacing={6}
      >
        <Grid item>
          <Card style={{ width: 230 }}>
            <CardActionArea component={RouterLink} to='/settings/user_management'>
              <CardContent>
                <Container maxWidth='sm'>
                  <PeopleIcon color='primary' style={{ fontSize: '56pt', margin: '0 auto', display: 'block' }} />
                </Container>
                <Container maxWidth='sm'>
                  <Typography variant='h6' style={{ textAlign: 'center' }}>Manage Users</Typography>
                </Container>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: 230 }}>
            <CardActionArea component={RouterLink} to='/settings/billing'>
              <CardContent>
                <Container maxWidth='sm'>
                  <PaymentIcon color='primary' style={{ fontSize: '56pt', margin: '0 auto', display: 'block' }} />
                </Container>
                <Container maxWidth='sm'>
                  <Typography variant='h6' style={{ textAlign: 'center' }}>Subscription</Typography>
                </Container>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: 230 }}>
            <CardActionArea component={RouterLink} to='/settings/templates'>
              <CardContent>
                <Container maxWidth='sm'>
                  <SvgIcon
                    component={ContentCopyIcon}
                    viewBox='0 0 24 24'
                    style={{ fontSize: '56pt', margin: '0 auto', display: 'block' }}
                    color='primary'
                  />
                </Container>
                <Container maxWidth='sm'>
                  <Typography variant='h6' style={{ textAlign: 'center' }}>Templates</Typography>
                </Container>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
